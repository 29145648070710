import React from 'react';
import { Button, Col, Form, Row, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

export const FormPapers = () => {
  return (
    <div>
      <Row justify="space-between">
        <Col>
          <h3>Papeles de Trabajo</h3>
        </Col>
      </Row>

      <Form name="validate_other">
        <Form.Item label="Comprobantes Fiscales">
          <Form.Item name="dragger" valuePropName="fileList" noStyle>
            <Upload.Dragger name="files" action="/upload.do" accept=".xml">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Da Click o Arrastra los documentos
              </p>
              <p className="ant-upload-hint">Soporto multiples archivos.</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
