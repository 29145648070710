import React from 'react';
import { FormPapers } from '../../Components/Papers/FormPapers';

export const PapersScreen = () => {
  return (
    <div className="papers-screen">
      <FormPapers />
    </div>
  );
};
