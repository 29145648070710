import React from 'react';
import { ClientTable } from '../../Components/Clients/ClientTable';

export const ClientsScreen = () => {
  return (
    <div className="client-screen">
      <ClientTable />
    </div>
  );
};
